import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import Navmenu from './NavMenu'



const Header7 = () => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='absolute right-[3%] left-[3%] z-[15] px-4 lg:px-6 my-6 rounded-[80px]'>
            <div className='flex  lg:flex-row'>
                <div className='w-full mr-0 md:w-[75%] lg:w-[25%] lg:mr-10 mt-0 lg:-mt-[30px] mb-0 lg:-mb-[50px] rounded-lg'>
                    <img
                        src={rpdata?.dbPrincipal?.logo}
                        alt='logo'
                        loading='lazy'
                        className=' w-full md:w-[80%] mx-auto pt-3 pb-5 '
                    />
                </div>

                <div className='block w-[40%] lg:w-[75%] self-center '>
                    <div className='flex justify-end'>
                        <div className='w-[50%] lg:w-[100%] flex items-center justify-end'>
                            <nav>
                                <Navmenu />
                            </nav>
                        </div>
                    </div>
                </div>

            </div>
        </header>
    )
}

export default Header7
